<template >
  <div id="managePassenger" class="Content-Page responesiveTable" >
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Passenger History</div>
      </div>
    </div>
    <div id="destiantionDetail" class="wrap-Main">
      <div class="box-S2">
        <div class="box-S4 B-carddetail">
          <div class="N-Page T-size-20">{{ user.full_name }}</div>
        </div>
        <div class="box-S4 B-carddetail">
          <div class="N-Page T-size-20">Company Name: {{user.c_name}}</div>
        </div>
      </div>
      <div class="box-S2">
        <div class="box-S4 B-carddetail">
            <div class="N-Page T-size-20">Total Outbound: {{user.p_out}}</div>
          </div>
        <div class="box-S4 B-carddetail">
            
           <div class="box-S3 noPadding">
            <div class="N-Page T-size-20">Total Inbound: {{user.p_in}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-S4 " style="padding-left:10px;padding-right:10px">
      <ejs-grid ref="gridReport1" id="GridReport1" :dataSource="report" :allowGrouping="false" :toolbar="toolbarOptions"
        :allowExcelExport="true" :toolbarClick="toolbarClickReport1" :allowPaging="false" :allowSorting="false"
        :allowMultiSorting="true" :allowTextWrap="true" :pageSettings='pageSettings' >
        <e-columns>
          <e-column field="f_flight_no" headerText="Flight No." headerTextAlign="Center" textAlign="Center" width="135"
            :disableHtmlEncode="false"></e-column>
          <e-column field="full_name" headerText="Name" headerTextAlign="Center" textAlign="Center" width="160"
            :disableHtmlEncode="false"></e-column>
          <e-column field="ap_depart_date" headerText="Departure Date" headerTextAlign="Center" textAlign="Center"
            width="120" :disableHtmlEncode="false"></e-column>
          <e-column field="pt_name" headerText="Passenger type" headerTextAlign="Center" textAlign="Center" width="115"
            :disableHtmlEncode="false"></e-column>
          <e-column field="c_name" headerText="company name" headerTextAlign="Center" textAlign="Center" width="115"
            :disableHtmlEncode="false"></e-column>
          <e-column field="u_jobposition" headerText="jobposition" headerTextAlign="Center" textAlign="Center"
            width="125" :disableHtmlEncode="false"></e-column>
          <e-column field="depart_ap" headerText="Departure airport" headerTextAlign="Center" textAlign="Center"
            width="120" :disableHtmlEncode="false"></e-column>
          <e-column field="dest_ap" headerText="Destination airport" headerTextAlign="Center" textAlign="Center"
            width="120" :disableHtmlEncode="false"></e-column>
          <e-column field="ac_name" headerText="Aircarft Name" headerTextAlign="Center" textAlign="Center" width="100"
            :disableHtmlEncode="false"></e-column>
          <e-column field="p_body_weight" headerText="Body Weight" headerTextAlign="Center" textAlign="Center"
            width="100" :disableHtmlEncode="false"></e-column>
          <e-column field="p_baggage_weight" headerText="Baggage Weight" headerTextAlign="Center" textAlign="Center"
            width="100" :disableHtmlEncode="false"></e-column>
          <e-column field="p_checkin_date" headerText="Date Checkin" headerTextAlign="Center" textAlign="Center"
            width="120" :disableHtmlEncode="false"></e-column>
          <e-column field="p_offload_remark" headerText="Offload remark" headerTextAlign="Center" textAlign="Center"
            width="120" :disableHtmlEncode="false"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>

  <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Delete Confirmation ?</span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex>Delete {{ deleteItem.FirstName }} ?</v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn   @click="closeDialog">Cancel</v-btn>
                <v-btn   :loading="loading" @click="deleteToServer">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


  <v-btn v-show="user.RoleId===2" color="red darken-1"  @click="closeDialog">Cancel</v-btn>-->
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { PdfLineJoin } from "@syncfusion/ej2-pdf-export";

//Grid จำเป็น
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import { json } from "body-parser";

export default {

  data: () => ({
    userdata: null,
    search: "",
    deleteItem: 0,
    dialogDelete: false,
    loading: false,
    userItem: [],
    headers: [
      { value: "f_flight_no", text: "Flight NO.", sortable: true },
      { value: "ap_depart_date", text: "Departure date", sortable: true, format: { type: 'dateTime', skeleton: 'short' } },
      { value: "pt_name", text: "Passenger type", sortable: true },
      { value: "depart_ap", text: "Departure airport", sortable: true },
      { value: "dest_ap", text: "Destination airport", sortable: true },
      { value: "ac_name", text: "Aircarft Name", sortable: true },
      { value: "p_offload_remark", text: "Offload remark", sortable: true },
    ],
    report: [],
    u_id: 0,
    user: {},
    toolbarOptions: ["ExcelExport"],
  }),
  computed: {},

  mounted: function () {
    this.u_id = this.$route.query.u_id;
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.renderUI();
  },
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport],
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    //ExportExcel
    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport1.excelExport({fileName:this.user.u_firstname+".xlsx" });
      }
    },

    async renderUI() {
      try {
        //User
        this.search = "";
        const q = {};
        //Flight เห็นหมดทุกคนสามารถขึ้นได้
        q.u_id = this.u_id
        let res = await feathersClientUOA.service("passenger-history").find({ query: q });
        this.user = res.headerData[0]
        this.report = res.data;
      } catch (err) {

      }
    }

  },
};
</script>
<style>
.e-headercelldiv {
  padding: 0rem !important;
}

.e-sortfilterdiv {
  display: none !important;
}

.e-numericitem:hover {
  color: white !important
}

div.e-icons {
  color: red !important;
  font-weight: 500;
}
    @media (-webkit-min-device-pixel-ratio: 1) {
       .responesiveTable {
    width: 100%;
  } 
    }
  
  @media (-webkit-min-device-pixel-ratio: 1.25)  {
  .responesiveTable {
    width: calc(100% - 384px)
  }
  }
</style>